<template>
  <div>
    <slot name="ButtonAction">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="open()" variant="primary" size="sm">
        Recargar
      </b-button>
    </slot>
    <b-modal v-model="showModal" centered :title="'Recargar Billetera en Dolares'" hide-footer no-close-on-backdrop>
      <b-overlay :show="loading" rounded responsive variant="transparent" opacity="0.33" blur="2px">
        <b-row>
          <b-col cols="12" md="12">
            <div>
              <validation-observer ref="rechargeForm">
                <b-form-group label="Tipo de Pago *">
                  <validation-provider #default="{ errors }" name="tipo de pago" rules="required">
                    <v-select v-model="payments" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentTypes" label="name" multiple :reduce="(item) => item" :return-object="true"
                      @input="typePayments(payments)">
                      <template v-slot:option="option">
                        {{ option.name }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <span class="mb-2 d-flex">Tasa del día : {{ rateDayFixed }}</span>
                <div v-for="(payment, index) in payments" :key="index">
                  <b-form-group :label="`${'Monto en Dolares: '} ${payment.name}`">
                    <validation-provider #default="{ errors }" name="monto" :min="1" v-model="payment.amountUSD"
                      rules="required">
                      <b-form-input v-model="payment.amountUSD" type="number" @input="changePrice(payment, 'USD')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group :label="`${'Monto en Bolivares: '} ${payment.name}`" v-if="
                    (payments.length > 0 &&
                      payment.code == 'TARJETA_CREDITO') ||
                    payment.code == 'TARJETA_DEBITO' ||
                    payment.code == 'TRANSFERENCIA' ||
                    payment.code == 'PAGO_MOVIL' ||
                    payment.code == 'EFECTIVO_BOLIVARES'
                  ">
                    <validation-provider #default="{ errors }" name="monto" v-model="payment.amountBS" rules="required">
                      <b-form-input v-model="payment.amountBS" type="number" @input="changePrice(payment, 'BS')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <div v-if="verifyPaymentType(payment)">
                    <b-form-group :label="`${'Nro de Referencia: '} ${payment.name}`">
                      <validation-provider #default="{ errors }" name="referencia" v-model="payment.refId"
                        rules="required">
                        <b-form-input v-model="payment.refId" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group :label="`${'Comprobante de Pago: '} ${payment.name}`">
                      <div class="text-center mt-1">
                        <b-avatar :src="getImageUrl(index)" size="150px" rounded v-if="getImageUrl(index)" />
                      </div>
                      <b-col class="d-flex justify-content-center p-2">
                        <b-button variant="primary" @click="triggerFileInput(index)">
                          Subir
                          <input :ref="`fileInput-${index}`" type="file" class="d-none"
                            @change="uploadImage($event, index)" />
                        </b-button>
                      </b-col>
                    </b-form-group>
                  </div>
                </div>
                <div v-if="typeBank.length > 0">
                  <h3>Nuestros bancos</h3>
                  <b-row class="mt-2">
                    <b-col v-for="(i, x) in typeBank" :key="x" class="mb-1">
                      <ul class="bank-list">
                        <li>
                          <h4>
                            {{ i.payment }}
                            <feather-icon icon="CopyIcon" size="1.1x" class="custom-class cursor-pointer"
                              @click="copyItemBank(i)" />
                          </h4>
                        </li>
                        <li>
                          {{ i.account }}

                          <feather-icon icon="CopyIcon" class="custom-class cursor-pointer"
                            @click="copyTextItem(i.account)" />
                        </li>
                        <li>
                          {{ i.name }}
                          <feather-icon icon="CopyIcon" class="custom-class cursor-pointer"
                            @click="copyTextItem(i.account)" />
                        </li>
                        <li>
                          {{ i.id_card }}
                          <feather-icon icon="CopyIcon" class="custom-class cursor-pointer"
                            @click="copyTextItem(i.id_card)" />
                        </li>
                        <li v-if="i.phone">
                          {{ i.phone }}
                          <feather-icon icon="CopyIcon" class="custom-class cursor-pointer" color="primary"
                            @click="copyTextItem(i.phone)" />
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                </div>
                <footer class="modal-footer p-0">
                  <div class="mt-1">
                    <b-button variant="outline-primary modal-btn mr-1" @click="close">Cancelar</b-button>
                    <!-- create loading  -->
                    <b-button variant="primary" :disabled="loading" @click="save">{{ loading ? "Procesando..." :
                      "Guardar" }}</b-button>
                  </div>
                </footer>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BOverlay,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BCard,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { localize } from "vee-validate";
import es from "vee-validate/dist/locale/es.json";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
localize("es", es);

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BTooltip,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    wallet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      disabled: true,
      showBank: false,
      paymentTypes: [],
      dataBanks: [],
      typeBank: [],
      payments: [],
      rateDay: null,
      rateDayFixed: null,
      showModal: false,
      loading: false,
      modeEdit: false,
      hasPagoMovil: false,
    };
  },
  computed: {},
  mounted() {
    this.getBanks();
  },
  methods: {
    copyTextItem(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Copiado al portapapeles",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Copiado: ${text}`,
            },
          });
        })
        .catch((err) => {
          console.error("No se pudo copiar el texto: ", err);
        });
    },
    copyItemBank(i) {
      const text = `${i.payment}:\n${i.account}\n${i.name}\n${i.id_card}\n${i.phone === null ? "" : i.phone
        }`;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Copiado al portapapeles",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Copiado datos de: ${i.payment}`,
            },
          });
        })
        .catch((err) => {
          console.error("No se pudo copiar el texto: ", err);
        });
    },
    verifyPaymentType(payment) {
      if (payment.code == "TRANSFERENCIA" || payment.code == "PAGO_MOVIL") {
        return true;
      }
      return false;
    },

    async typePayments(payments) {
      console.log(payments)
      this.typeBank = [];
      for (const payment of payments) {
        const filteredBanks = await this.filterBanksType(
          this.dataBanks?.banks,
          payment.code.toLowerCase()
        );
        this.typeBank = [...this.typeBank, ...filteredBanks];
      }
      // Elimina duplicados si los hay
      this.typeBank = [
        ...new Set(this.typeBank.map((bank) => JSON.stringify(bank))),
      ].map((bank) => JSON.parse(bank));
    },

    async filterBanksType(banks, paymentType) {
      return banks
        .filter(
          (bank) =>
            (paymentType === "pago_movil" &&
              bank.type.toLowerCase().includes("pago móvil")) ||
            (paymentType === "transferencia" &&
              bank.type.toLowerCase().includes("corriente"))
        )
        .map((bank) => ({
          ...bank,
          payment:
            paymentType === "pago_movil" ? "Pago Móvil" : "Transferencia",
        }));
    },

    async changePrice(payment, currency) {
      if (currency == "USD") {
        payment.amountBS = (payment.amountUSD * this.rateDay?.amount).toFixed(
          2
        );
        if (payment.amountBS == 0) {
          payment.amountBS = "";
        }
      } else {
        payment.amountUSD = (payment.amountBS / this.rateDay?.amount).toFixed(
          2
        );
        if (payment.amountUSD == 0) {
          payment.amountUSD = "";
        }
      }
    },

    async getBanks() {
      this.dataBanks = await this.$store.dispatch("bank/index");
      this.rateDay = await this.$store.dispatch("bank/index", {
        params: { rate: "now" },
      });
      this.rateDayFixed = (1 * this.rateDay?.amount).toFixed(
        2
      );

      if (!this.rateDay?.amount || this.rateDay?.amount === 0) {
        this.close()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "XCircleIcon",
            variant: "danger",
            text: `No se encontró la tasa del día, por ende no se puede realizar una recarga`,
          },
        });
      }
    },
    async open() {
      this.paymentTypes = await this.$store.dispatch("products/getPayments", {
        payment: "RechargeMode",
      });
      this.showModal = true;
    },
    uploadImage(event, index) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.$set(this.payments[index], "imageUrl", e.target.result);
      };
      reader.readAsDataURL(file);
    },
    getImageUrl(index) {
      return this.payments[index]?.imageUrl || "";
    },
    triggerFileInput(index) {
      this.$refs[`fileInput-${index}`][0].click();
    },
async save() {
  try {
    if (!(await this.$refs.rechargeForm.validate())) {
      return;
    }

    for (let i = 0; i < this.payments.length; i++) {
      const payment = this.payments[i];

      if (this.verifyPaymentType(payment)) {
        if (payment.amountUSD < 1) {
          this.showToastError("El monto debe ser mayor a 1 dólar");
          return;
        }
        if (!payment.imageUrl) {
          this.showToastError(`El campo Comprobante de Pago es requerido para ${payment.name}`);
          return;
        }
        if (payment.refId.length < 4) {
          this.showToastError(`El campo Nro de Referencia debe tener mínimo 4 caracteres para ${payment.name}`);
          return;
        }
        if (payment.refId == 0) {
          this.showToastError(`El campo Nro de Referencia no puede ser 0 para ${payment.name}`);
          return;
        }
        if (payment.amount <= 0) {
          this.showToastError(`El campo Monto debe ser mayor a 0 para ${payment.name}`);
          return;
        }
      }
    }

    this.loading = true;

    const response = await this.$store.dispatch("user/rechargeMode", {
      walletId: this.wallet.id,
      data: this.payments.map((payment) => {
        return {
          paymentMethod: payment.code,
          amount: parseFloat(payment.amountUSD),
          amountBS: parseFloat(payment.amountBS),
          refId: payment.refId,
          screenshot: payment.imageUrl,
        };
      }),
    });

    this.close();
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Éxito",
        icon: "CheckCircleIcon",
        variant: "success",
        text: response.data.message,
      },
    });

    try {
      await this.$store.dispatch("user/showUser", this.$route.params.id);
    } catch (error) {
      this.showToastError("Error al recargar la información del usuario");
    }
  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      this.showToastError("La solicitud tardó demasiado tiempo. Inténtalo de nuevo.");
    } else if (error.response && error.response.data && error.response.data.message) {
      this.showToastError(error.response.data.message);
    } else {
      this.showToastError("Ocurrió un error inesperado. Intente nuevamente.");
    }
  } finally {
    this.loading = false;
  }
},

showToastError(message) {
  this.$toast({
    component: ToastificationContent,
    props: {
      title: "Error",
      icon: "XCircleIcon",
      variant: "danger",
      text: message,
    },
  });
},

    close() {
      this.showModal = false;
      this.$refs.rechargeForm.reset();
      this.payments = [];
      this.paymentTypes = [];
      this.typeBank = [];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bank-list {
  list-style-type: none;
  padding-left: 0;
}

.bank-list li {
  margin-bottom: 10px;
}

.bank-list li .b-button {
  margin-left: 10px;
}
</style>
